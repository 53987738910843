@import "./variables.less";

/*******************************************************************************
╦═╗┌─┐┌─┐┌─┐┌┬┐  ╦ ╦┌─┐┌─┐┬┌─  ╔═╗┌─┐┬─┐┌┬┐┌─┐
╠╦╝├┤ ├─┤│   │   ╠═╣│ ││ │├┴┐  ╠╣ │ │├┬┘│││└─┐
╩╚═└─┘┴ ┴└─┘ ┴   ╩ ╩└─┘└─┘┴ ┴  ╚  └─┘┴└─┴ ┴└─┘
********************************************************************************/
.rhf-label {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0.75em;
  padding-top: 0.4em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #e5e5e5;
}

.rhf-label-single-line {
  white-space: nowrap;
  overflow: hidden;
  display: block !important;
  text-overflow: ellipsis;
}

.rhf-label-noborder {
  .rhf-label();
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.rhf-label-nofill {
  width: fit-content;
  padding-right: 0.75em;
}

.rhf-empty-label {
  color: #bbb;
}

.rhf-checkboxgroup-item-label {
  flex-direction: row;
  white-space: nowrap;
  padding-right: 1.5em;
}

/*******************************************************************************
 ╔╦╗┌─┐┌┐   ╔═╗┌┬┐┬─┐┬┌─┐
  ║ ├─┤├┴┐  ╚═╗ │ ├┬┘│├─┘
  ╩ ┴ ┴└─┘  ╚═╝ ┴ ┴└─┴┴  
********************************************************************************/
.tab-container {
  .tabs-column {
    display: flex;
  }

  .buttons-right {
    justify-content: flex-end;
    display: flex;
    padding: 0;
    @media (max-width: 576px) {
        display: none;
    }
    // &.ant-col-xs-24 {
    //     display: none;
    // }
    // &.ant-col-sm-24 {
    //     display: none;
    // }
  }

  .tab-text {
    display: flex;
    justify-content: center;
    font-family: "Montserrat-SemiBold";
    font-size: 1em;
    align-items: center;

    @media (max-width: 768px) {
        width:6em;
        justify-content: start;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }

  .tab-control {
    .ant-tabs-nav {
      //   .ant-tabs-nav-wrap {
      //     .ant-tabs-nav-list {
      //     }
      //   }

      .ant-tabs-ink-bar {
        height: 0;
      }

      .ant-tabs-tab {
        padding: 0.8em;
        background-color: #f2f2f2;
        border-color: #dfdfdf;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 0px;
        margin-left: 0px;
        border-radius: 0.5em 0.5em 0 0;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #ffffff;
        border-bottom-width: 0px;
        overflow-y: visible;
        box-shadow: 0px 3px 0px -1px #ffffff;

        .ant-typography {
          color: #3b5998;
        }
      }
    }
  }
}

/*******************************************************************************
╔╗ ┬ ┬┌┬┐┌┬┐┌─┐┌┐┌┌─┐
╠╩╗│ │ │  │ │ ││││└─┐
╚═╝└─┘ ┴  ┴ └─┘┘└┘└─┘
********************************************************************************/
.plus-add-button {
  font-weight: bolder;
  background-color: @brand-standard-blue;
  border-color: @brand-standard-blue;
  color: #ffffff;
}

.shared-button-mixin {
  min-width: 90px;
  border-radius: 4px;
  font-weight: bolder;
  color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  &:visited,
  &:active,
  &:hover,
  &:focus {
    color: #ffffff;
  }
  &:visited,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #ffffff;
  }
  &:hover {
    color: #ffffff;
    background-color: #ffffff;
  }
}
.ant-btn {
  &.edit-button {
    .shared-button-mixin();
    background-color: @brand-standard-blue;
    border-color: @brand-standard-blue;
    &:focus {
      background-color: @brand-standard-blue;
    }
    &:hover {
      color: @brand-standard-blue;
    }
  }
  &.save-button {
    .shared-button-mixin();
    background-color: @brand-standard-green;
    border-color: @brand-standard-green;
    &:focus {
      background-color: @brand-standard-green;
    }
    &:hover {
      color: @brand-standard-green;
    }
  }
  &.cancel-button {
    .shared-button-mixin();
    background-color: @brand-standard-red;
    border-color: @brand-standard-red;
    &:focus {
      background-color: @brand-standard-red;
    }
    &:hover {
      color: @brand-standard-red;
    }
  }
}
  
  /*******************************************************************************
  ╔═╗┌─┐┬─┐┌┬┐  ╔═╗┌─┐┬─┐┌┬┐  ╦  ┌─┐┬ ┬┌─┐┬ ┬┌┬┐
  ║  ├─┤├┬┘ ││  ╠╣ │ │├┬┘│││  ║  ├─┤└┬┘│ ││ │ │ 
  ╚═╝┴ ┴┴└──┴┘  ╚  └─┘┴└─┴ ┴  ╩═╝┴ ┴ ┴ └─┘└─┘ ┴ 
  ********************************************************************************/
  .standard-card-layout {
    box-shadow: 8px 9px 24px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
  
    .ant-card-head {
      margin-bottom: 0;
      border-bottom: 0 !important;
  
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-color: #dfdfdf;
  
      overflow: visible;
  
      .ant-card-head-wrapper {
        overflow: visible;
        .ant-card-head-title {
          overflow: hidden;
        }
      }
    }
  
    .ant-card-body {
      position: relative;
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
    }
  }
  
  .standard-form-card-layout {
    .standard-card-layout();

    .ant-card-body {
      padding-left: 1.5em;
      padding-right: 1em;
      padding-bottom: 2em;
    }
    .ant-card-head-title {
      padding-bottom: 0px;
    }
    .shared-padding-mixin {
      padding: 0.25em;
    }
    .card-header-text {
      margin: 0 !important;

    }
    .label-cell {
      .shared-padding-mixin();
      font-family: "Montserrat-SemiBold";
      white-space: nowrap;
    }
    .form-cell {
      .shared-padding-mixin();
    }
    .text-adjacent {
      line-height: 2.25em;
    }
    h2 {
      font-size: 1.25em;
      margin-top: 1em;
    }
    .rhf-checkboxgroup-item-label {
      font-family: "Montserrat-SemiBold";
    }    
    .rhf-disable-pointer {
      pointer-events: none;
      input[type=checkbox], input[type=radio] {
        pointer-events: none;
      }
    }
  }
  
  /* ----- ANTD MEDIA QUERY SIZES FOR XS, SM, MD, LG, XL -----
  @media o(max-width: 480px) {}
  @media (min-width: 576px) {}
  @media (min-width: 768px) {}
  @media (min-width: 992px) {}
  @media (min-width: 1200px) {}
  */
