.assign-smash-picker {
    // background-color: #ff0000;
    .ant-picker-panel-container {
        // background-color: aquamarine;
        .ant-picker-ranges {
            // background-color: orange;
            // display: none;
            button {
                visibility: hidden;
                padding: 0;
              }
              button:after {
                content:'MOVE'; 
                visibility: visible;
                display: block;
                position: absolute;
                background-color: #3b5998;
                padding: 2px 5px;
                top: 0px;
                border-radius: 4px;
                margin-left: -30px;
                color: #ffffff
              }
        }
    }

    .ant-picker-cell-inner {
        // background-color: orange;
        &:hover {
            background-color: #3b5998;
        }
    }
}
@primary-color: #3B5998;@info-color: #0173A8;@success-color: #27AE60;@warning-color: #E2B93B;@error-color: #EB5757;@progress-bar-empty-color: #d9d9d9;@another-gray-df: #dfdfdf;@border-radius-base: 4px;@card-radius: 11px;@component-background: #ffffff;@body-background: #fff;@font-family: 'Open Sans', 'Montserrat', 'DejaVu Sans', 'Verdana', 'sans‑serif';@layout-footer-background: #494949;@layout-footer-padding: 11px 20px;@text-color: #5B5B5B;@white: #fff;@link-color: #3B5998;@menu-highlight-color: #3B5998;@menu-item-boundary-margin: 0;@table-header-bg: linear-gradient(360deg, #D9D9D9 -88.33%, rgba(217, 217, 217, 0) 71.67%);@card-head-background: #f2f2f2;@card-head-font-size: 20px;@card-head-padding: 12px;@card-shadow: 2px 4px 6px rgba(0, 0, 0, 0.20);@header-font-family: 'Oswald', 'Avenir Next Condensed', 'Arial Narrow', 'MS UI Gothic', 'sans‑serif';@green: #79BC7F;@red: #BB1E10;@blue: #3B5998;@grey: #F2F2F2;