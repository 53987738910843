@import "./less/core-components.less";

@text-color: #5b5b5b;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, .ant-typography[level="1"] {
  font-family: 'Montserrat-Bold';
  font-style: bold;
  font-weight: 600;
  font-size: 28px;
  // line-height: 150%;
  /* identical to box height, or 42px */

  text-transform: uppercase;

  color: #000000;
}

h2, .ant-typography[level="2"] {
  font-family: 'Montserrat-Bold';
  font-style: bold;
  font-weight: 600;
  font-size: 22px;
  // line-height: 120%;
  /* or 26px */

  text-transform: uppercase;

  color: #000000;
}

.table-header, .ant-table-column-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  // line-height: 150%;
  /* identical to box height, or 21px */

  text-transform: uppercase;

  color: @text-color;
}

.ant-menu-item-icon {
  font-size: 16px !important;
}

.ant-menu-item-selected {
  .ant-menu-title-content {
    font-family: 'Montserrat-Bold';
  }
}
.ant-menu-title-content {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  // line-height: 150%;
  /* identical to box height, or 21px */

  color: @text-color;
}

.hide-trigger {
  .ant-layout-sider-zero-width-trigger {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: none;
    display: none;
  }
}

.show-trigger {
  .ant-layout-sider-zero-width-trigger {
    position: fixed !important;
    top: 15px !important;
    left: 12px !important;
    right: none;
    display: block;
    background: none;
    color: #001529;
  }
}

.ant-input {
  font-size: 10px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: #ff4d4f;
}

.ant-form-explain {
  color: #ff4d4f;
}

/* I am having trouble getting this through styled components */
.card-form-row {
  border-top: 1px solid #E5E5E5;
}

.card-form-row-even {
  background-color: rgba(217, 217, 217, 0.15);
}

.card-form-row-first {
  border-top: 0;
}

.asterisk:after {
  content:" *";
  color: red;
}

.empty-label {
  color: #bbb;
}

//////////// ********* ant overrides  *************** ///////////////////
.ant-table-column-sorter-inner {
  display: none !important;
}

.ant-table-column-sort {
  border-top: none;
}

.ant-table-thead {
  th.ant-table-column-has-sorters:hover {
   background: linear-gradient(360deg,#a9a9a9 -88.33%,rgba(217,217,217,0) 71.67%) !important;
  }
}

.ant-table-thead tr th {
  border-top: none !important;
  word-break: break-word;
}

th.ant-table-column-sort {
  background: linear-gradient(360deg,#a9a9a9 -88.33%,rgba(217,217,217,0) 71.67%) !important;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ant-table-tbody td.ant-table-column-sort {
  background-color: rgba(242, 242, 242, 1);
  background: rgb(245, 243, 243);
  transition: background-color .5s;;
}

.ant-table-tbody tr.ant-table-row:hover td {
  background-color: rgba(245, 243, 243, 1);
  background: rgb(245, 243, 243);
  transition: background-color .5s;
}


// for menu
.visually-hidden {
  clip-path: inset(100%);
  clip: rect(20px, 20px, 20px, 20px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media only screen 
  and (min-width: 768px) 
  and (max-width: 1024px) { 


    .ant-table-thead tr th {
      padding: 8px !important; 
    }
    
    .ant-table-column-title {
      font-size: 10px !important;

    }

    .ant-tabs-nav .ant-tabs-tab {
      padding: 10px 8px !important;

      span {
        font-size: 10px !important;
      }
    }
}
@primary-color: #3B5998;@info-color: #0173A8;@success-color: #27AE60;@warning-color: #E2B93B;@error-color: #EB5757;@progress-bar-empty-color: #d9d9d9;@another-gray-df: #dfdfdf;@border-radius-base: 4px;@card-radius: 11px;@component-background: #ffffff;@body-background: #fff;@font-family: 'Open Sans', 'Montserrat', 'DejaVu Sans', 'Verdana', 'sans‑serif';@layout-footer-background: #494949;@layout-footer-padding: 11px 20px;@text-color: #5B5B5B;@white: #fff;@link-color: #3B5998;@menu-highlight-color: #3B5998;@menu-item-boundary-margin: 0;@table-header-bg: linear-gradient(360deg, #D9D9D9 -88.33%, rgba(217, 217, 217, 0) 71.67%);@card-head-background: #f2f2f2;@card-head-font-size: 20px;@card-head-padding: 12px;@card-shadow: 2px 4px 6px rgba(0, 0, 0, 0.20);@header-font-family: 'Oswald', 'Avenir Next Condensed', 'Arial Narrow', 'MS UI Gothic', 'sans‑serif';@green: #79BC7F;@red: #BB1E10;@blue: #3B5998;@grey: #F2F2F2;